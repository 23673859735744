/* Dependencies */
import { PropsWithChildren } from 'react';
import { TypeThemeFields } from '../../../models/contentful/TypeTheme';

export interface ComponentWrapperProps extends PropsWithChildren {
  className?: string;
  backgroundColor?: TypeThemeFields['colourScheme'];
}

export const theme = Object.freeze({
  Transparent: 'bg-fill',
  Red: 'theme-red bg-fill',
  Blue: 'theme-blue bg-fill',
  Plum: 'theme-plum bg-fill',
});
