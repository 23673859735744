/* Dependencies */
import { ContentfulServices } from './Contentful/Contentful.service';

/**
 * HTTP Client
 * @class
 */
class HTTPClient {
  public contentful: ContentfulServices;

  constructor() {
    this.contentful = new ContentfulServices();
  }
}

// Export shared instance
export const httpClient = new HTTPClient();
