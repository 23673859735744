/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Components
import { Image } from '../../Molecules/Image/Image';
import { Link } from '../../Atoms/Link/Link';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { NavigationDesktop } from '../../Molecules/NavigationDesktop/NavigationDesktop';

// Models
import { TypeTemplateHeader } from '../../../models/contentful';
import { NavigationMobile } from '../../Molecules/NavigationMobile/NavigationMobile';

/**
 * Header Component
 * @param props - Component props
 */
export const Header: FunctionComponent<TypeTemplateHeader> = ({
  fields: { logo, contrastLogo, menuItems, callToAction },
}) => {
  return (
    <header className="fixed z-20 w-full bg-fill">
      <div className="mx-auto max-w-1716 px-5 lg:px-14">
        <div className="relative flex items-center justify-between py-5 sm:space-x-6 md:space-x-8 lg:static lg:min-h-[120px] lg:pb-7 lg:pt-8">
          <div className="max-w-[150px] flex-grow basis-auto lg:max-w-[220px]">
            <Link to="/" title="Main Logo" openInNewTab={false} variant="none">
              {logo && <Image file={logo} aspectRatio="fluid" />}
            </Link>
          </div>

          <NavigationDesktop menuItems={menuItems} />

          {callToAction && (
            <div className="flex basis-auto items-center justify-end">
              <LinkButton
                variant={callToAction.fields.theme?.fields.colourScheme}
                arrowDirection="None"
                className="hidden lg:block"
                openInNewTab={callToAction.fields.openInNewTab}
                to={getLinkUrl(callToAction)}
              >
                {callToAction.fields.title}
              </LinkButton>
            </div>
          )}

          <NavigationMobile contrastLogo={contrastLogo} menuItems={menuItems} />
        </div>
      </div>
    </header>
  );
};
