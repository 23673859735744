/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { prefixSlug } from '../../../helpers/prefixSlug/prefixSlug';

//Components
import { Link } from '../../Atoms/Link/Link';

// Contentful
import {
  BLOCKS,
  MARKS,
  TopLevelBlock,
  INLINES,
} from '@contentful/rich-text-types';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';

// Models
import { PlainRichTextProps } from './PlainRichText.model';
import { getSlugUrl } from '../../../helpers/getSlugUrl/getSlugUrl';

// Options
const renderOptions: Options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <em>{text}</em>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    // Render Paragraph without node. For use within Heading, Paragraph, etc.
    [BLOCKS.PARAGRAPH]: (_node, children) => <span>{children}</span>,
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data?.uri.startsWith('/')) {
        return (
          <Link to={prefixSlug(node.data.uri)} variant="none">
            {children}
          </Link>
        );
      }
      return (
        <Link to={node.data?.uri} variant="none">
          {children}
        </Link>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node, children) => {
      return (
        <Link
          variant="none"
          openInNewTab={false}
          to={getSlugUrl(node.data.target)}
        >
          {children}
        </Link>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      return (
        <Link variant="none" to={node.data.target.fields.file.url}>
          {children}
        </Link>
      );
    },
  },
};

/**
 * Plain Rich Text Component
 * Used to render rich text as plain content for use in other components.
 * @params props - Required component props.
 */
export const PlainRichText: FunctionComponent<PlainRichTextProps> = ({
  content,
}) => {
  if (content == undefined) {
    return null;
  }
  return (
    <span className="grid grid-cols-1 gap-4 whitespace-pre-line lg:gap-6">
      {documentToReactComponents(
        {
          data: content.data,
          nodeType: BLOCKS.DOCUMENT,
          content: content.content as TopLevelBlock[],
        },
        renderOptions
      )}
    </span>
  );
};
