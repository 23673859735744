/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Components
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';

// Models
import { CTATandemProps } from './CTATandem.model';

/**
 * CTATandem - Group of buttons.
 * @param props - Required component props.
 * @returns
 */
export const CTATandem: FunctionComponent<CTATandemProps> = ({
  className = 'flex flex-wrap items-center justify-center',
  callsToAction,
  animation = true,
}) => {
  return (
    <>
      {callsToAction && (
        <div
          {...(animation ? { 'data-aos': 'fade-up' } : {})}
          className={`${className || ''} -mx-2.5 gap-y-8 lg:-mx-3`}
        >
          {callsToAction?.map((link, index) => {
            const theme_color = link.fields.theme?.fields.colourScheme;
            return (
              <div
                key={link.fields.title + index}
                className="flex items-center px-2.5 lg:px-3"
              >
                <LinkButton
                  arrowDirection={link.fields.arrowDirection}
                  variant={theme_color}
                  openInNewTab={link.fields.openInNewTab}
                  to={getLinkUrl(link)}
                  hasIcon={
                    link.fields.assetLink && link.fields.assetLink.fields
                      ? true
                      : false
                  }
                >
                  {link.fields.title}
                  {link.fields.assetLink && link.fields.assetLink.fields && (
                    /* Download Icon */
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="ml-1 h-3 w-3 lg:h-4 lg:w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  )}
                </LinkButton>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
