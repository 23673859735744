export type Variant = 'small' | 'medium' | 'base' | 'large';
export type Font = 'font-display-a' | 'font-display-s';
export interface ParagraphProps extends React.PropsWithChildren {
  className?: string;
  variant?: Variant;
  font?: Font;
}

/**
 * Get the style associated with the paragraph.
 * @param variant - The variant of the paragraph.
 * @returns
 */
export const getParagraphStyle = (variant?: Variant): string => {
  switch (variant) {
    case 'small': {
      return 'text-tiny lg:text-small';
    }
    case 'medium': {
      return 'text-small lg:text-2xl';
    }
    case 'base': {
      return 'text-small lg:text-base';
    }
    case 'large': {
      return 'text-base lg:text-3xl';
    }
    default: {
      return 'text-base';
    }
  }
};
