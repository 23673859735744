/* Dependencies */
import { Fragment, FunctionComponent, useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';

// Components
import { SubMenuItem } from '../../Atoms/SubMenuItem/SubMenuItem';
import { Link } from '../../Atoms/Link/Link';
import { Image } from '../Image/Image';

// Models
import { NavigationMobileProps } from './NavigationMobile.model';
import {
  TypeHeaderDrawerCardGridFields,
  TypeMoleculeDrawerMenuItemsFields,
  TypeMoleculeHeaderDropDownFields,
  TypeMoleculeHeaderMenuItemFields,
} from '../../../models/contentful';

// Helpers
import { getWindowWidth } from '../../../../src/helpers/getWindowWidth/getWindowWidth';
import { getHeaderLinkUrl } from '../../../helpers/getHeaderLinkUrl/getHeaderLinkUrl';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

/**
 * Mobile Navigation
 * @param props - Component props
 */
export const NavigationMobile: FunctionComponent<NavigationMobileProps> = ({
  menuItems,
  contrastLogo,
}) => {
  const [panelActive, setPanelActive] = useState(false);
  const [childActive, setChildActive] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', panelActive);
    document.body.classList.toggle('fixed', panelActive);
    document.body.classList.toggle('w-full', panelActive);
  }, [panelActive]);

  useEffect(() => {
    const resizeListener = () => {
      if (getWindowWidth() >= 1024 && panelActive) {
        setPanelActive(false);
        document.body.classList.remove('overflow-hidden', 'fixed', 'w-full');
      }
    };
    if (panelActive === false) setChildActive(false);
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, [panelActive]);

  return (
    <Popover className="mobile_navigation lg:hidden">
      {({ close }) => {
        return (
          <>
            {panelActive && (
              <div className="absolute left-0 top-1/2 z-20 w-full max-w-[150px] flex-grow basis-auto -translate-y-1/2 lg:max-w-[220px]">
                <Link
                  to="/"
                  title="Contrast Logo"
                  openInNewTab={false}
                  variant="none"
                >
                  {contrastLogo && (
                    <Image file={contrastLogo} aspectRatio="fluid" />
                  )}
                </Link>
              </div>
            )}
            <Popover.Button
              onClick={() => {
                setPanelActive(!panelActive);
              }}
              className="text-midnight-black overflow-x-none inline-flex items-center justify-center rounded-md p-2 focus:outline-none"
            >
              <span className="sr-only">Open menu</span>
              {panelActive ? (
                <XIcon
                  className="z-20 h-10 w-10 text-white"
                  aria-hidden="true"
                />
              ) : (
                <MenuIcon className="h-10 w-10 text-plum" aria-hidden="true" />
              )}
            </Popover.Button>
            <Transition
              show={panelActive}
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                static
                focus
                className="fixed inset-x-0 top-0 z-10 h-screen origin-top-right transform bg-plum pt-24 transition"
              >
                <nav className="relative h-full overflow-x-hidden px-10 pt-8 pb-20">
                  {menuItems.map((menuEntry, menuEntryIndex) => {
                    // Check if the entry contains a sub menu.
                    let isSubmenu = Object.hasOwnProperty.call(
                      menuEntry.fields,
                      'drawerContent'
                    );

                    // Handle Regular Link
                    if (!isSubmenu) {
                      const menuItem =
                        menuEntry.fields as TypeMoleculeHeaderMenuItemFields;

                      return (
                        <div
                          key={menuEntryIndex}
                          onClick={() => {
                            setPanelActive(false);
                            close();
                          }}
                        >
                          <SubMenuItem
                            openInNewTab={menuItem.openInNewTab}
                            linkTitle={menuItem.menuTitle}
                            className={`font-display-a text-3xl ${
                              childActive ? 'text-white/50' : 'text-white'
                            } `}
                            url={getHeaderLinkUrl(menuItem)}
                          />
                        </div>
                      );
                    }

                    // Handle Sub Menu
                    const menuItem =
                      menuEntry.fields as TypeMoleculeHeaderDropDownFields;
                    const drawer = menuItem.drawerContent
                      .fields as TypeMoleculeDrawerMenuItemsFields;
                    const drawerCards = menuItem.drawerContent
                      .fields as TypeHeaderDrawerCardGridFields;
                    return (
                      <Popover key={menuEntryIndex}>
                        {({ open }) => (
                          <>
                            <Popover.Button
                              onClick={() => {
                                setChildActive(!open);
                              }}
                              className={`group relative mx-auto mb-7 flex items-center pl-3 font-display-a focus:outline-none`}
                            >
                              <span
                                className={`text-3xl ${
                                  open ? 'text-red' : 'text-white'
                                } ${
                                  childActive && !open
                                    ? 'text-white/50'
                                    : 'text-white'
                                }`}
                              >
                                {menuItem.menuTitle}
                              </span>
                              <span className="ml-2.5 flex h-3 w-3 items-center justify-center rounded-full bg-white">
                                <ChevronDownIcon
                                  className={`h-4 w-4 text-plum ${
                                    open ? 'rotate-180' : ''
                                  } transition-transform`}
                                  aria-hidden="true"
                                />
                              </span>
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="opacity-0 scale-95 translate-y-1"
                              enterTo="opacity-100 scale-100 translate-y-0"
                              leave="transition ease-out duration-100"
                              leaveFrom="opacity-100 scale-100 translate-y-0"
                              leaveTo="opacity-0 scale-95 translate-y-1"
                            >
                              <Popover.Panel className="mx-auto h-full w-full max-w-400 transform overflow-auto bg-plum">
                                {/* First Level */}
                                {menuItem.callToActions?.map((link, index) => {
                                  if (index === 0) {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() => {
                                          setPanelActive(false);
                                          close();
                                        }}
                                      >
                                        <SubMenuItem
                                          className="text-3xl text-white"
                                          openInNewTab={
                                            link.fields.openInNewTab
                                          }
                                          linkTitle={menuItem.title}
                                          url={getLinkUrl(link)}
                                        />
                                      </div>
                                    );
                                  }
                                })}
                                {/* Second Level */}
                                {drawer.menuItems?.map((link, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setPanelActive(false);
                                      close();
                                    }}
                                  >
                                    <SubMenuItem
                                      className="text-3xl text-white"
                                      openInNewTab={link.fields.openInNewTab}
                                      linkTitle={link.fields.title}
                                      url={getHeaderLinkUrl(link.fields)}
                                    />
                                  </div>
                                ))}
                                {/* Third Level */}
                                {drawerCards.cards?.map((link, index) => (
                                  <div
                                    key={index}
                                    onClick={() => {
                                      setPanelActive(false);
                                      close();
                                    }}
                                  >
                                    <SubMenuItem
                                      className="text-3xl text-white"
                                      openInNewTab={link.fields.openInNewTab}
                                      linkTitle={link.fields.title}
                                      url={getHeaderLinkUrl(link.fields)}
                                    />
                                  </div>
                                ))}
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    );
                  })}
                </nav>
              </Popover.Panel>
            </Transition>
          </>
        );
      }}
    </Popover>
  );
};
