/* Dependencies */
import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

// Helpers
import { isLinkExternal } from '../../../helpers/isLinkExternal/isLinkExternal';

// Models
import { LinkProps, variants, iconVariants } from './Link.model';

/**
 * Link component
 * @param props - Component props.
 */
export const Link: FunctionComponent<LinkProps> = ({
  children,
  className,
  to,
  openInNewTab = true,
  title,
  locale,
  variant = 'none',
  arrowDirection,
}) => {
  const isExternalUrl = isLinkExternal(to);

  // Handle External Url
  if (isExternalUrl) {
    return (
      <a
        href={to}
        className={`${className || ''} font-display-a ${
          variants[variant]
        } transition-colors ${
          variant != 'none' ? 'flex items-center' : 'inline'
        } `}
        rel="noopener noreferrer"
        target={`${openInNewTab ? '_blank' : '_self'}`}
        title={title}
      >
        {arrowDirection === 'Left' && variant !== 'none' && (
          <ChevronLeftIcon
            className={`mr-1 h-5 w-5 
            ${iconVariants[variant]}
            `}
            aria-hidden="true"
          />
        )}

        {children}

        {arrowDirection === 'Right' && variant !== 'none' && (
          <ChevronRightIcon
            className={`ml-1 h-5 w-5 
            ${iconVariants[variant]}
            `}
            aria-hidden="true"
          />
        )}
      </a>
    );
  }

  // Handle Internal Url
  return (
    <NextLink href={to} locale={locale || undefined}>
      <a
        className={`${className || ''} font-display-a ${
          variants[variant]
        } transition-colors ${
          variant != 'none' ? 'flex items-center' : 'inline'
        } `}
        target={`${openInNewTab ? '_blank' : '_self'}`}
        title={title}
      >
        {arrowDirection === 'Left' && variant !== 'none' && (
          <ChevronLeftIcon
            className={`mr-1 h-5 w-5 
            ${iconVariants[variant]}
            `}
            aria-hidden="true"
          />
        )}

        {children}

        {arrowDirection === 'Right' && variant !== 'none' && (
          <ChevronRightIcon
            className={`ml-1 h-5 w-5 
            ${iconVariants[variant]}
            `}
            aria-hidden="true"
          />
        )}
      </a>
    </NextLink>
  );
};
