import { TypeCallToActionFields } from '../../../models/contentful';

export interface LinkProps extends React.PropsWithChildren {
  /**
   * Class Name
   */
  className?: string;
  /**
   * Static and dynamic routes
   */
  to: string;
  /**
   * Link title.
   */
  title?: string;
  /**
   * Link new tab.
   */
  openInNewTab?: boolean;
  /**
   * Link Locale
   */
  locale?: string;
  /**
   * Link Variant.
   */
  variant?: keyof typeof variants;
  /**
   * Arrow Direction (links, CTA's)
   */
  arrowDirection?: TypeCallToActionFields['arrowDirection'];
}

export interface Variants {
  base: string;
  none: string;
  primary: string;
  secondary: string;
}

export const variants: Variants = Object.freeze({
  base: 'text-primary hover:text-secondary active:text-secondary text-2xl',
  none: 'text-primary hover:text-secondary active:text-secondary',
  primary: 'text-plum hover:text-red active:text-red text-2xl',
  secondary: 'text-white hover:text-blue active:text-blue text-2xl',
});

export interface IconVariants {
  base: string;
  primary: string;
  secondary: string;
}

export const iconVariants: IconVariants = Object.freeze({
  base: 'text-link-icon',
  primary: 'text-red',
  secondary: 'text-blue',
});
