/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Link } from '../Link/Link';

// Models
import { SubMenuItemProps } from './SubMenuItem.model';
import { PlainRichText } from '../PlainRichText/PlainRichText';

/**
 *  Navigation SubMenuItem
 * @param props - Component props
 */
export const SubMenuItem: FunctionComponent<SubMenuItemProps> = ({
  className,
  url,
  linkTitle,
  openInNewTab,
}) => {
  return (
    <Link
      to={url}
      variant="none"
      openInNewTab={openInNewTab}
      className={`${
        className || ''
      } mb-7 flex items-center justify-center text-center outline-none`}
    >
      <PlainRichText content={linkTitle} />
    </Link>
  );
};
