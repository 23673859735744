/* Dependencies */
import { createClient } from 'contentful';

/**
 * Contentful Preview Client
 */
export const contentfulPreviewClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_API_KEY as string,
  host: 'preview.contentful.com',
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as string,
});
