/* Dependencies */
import { createClient } from 'contentful';

/**
 * Contentful Client
 */
export const contentfulClient = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_API_KEY as string,
  environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT as string,
  removeUnresolved: true,
});
