import { TypeMoleculeHeaderDropDownFields } from '../../../models/contentful';

export interface MegaDropdownMenuProps {
  menuItem: TypeMoleculeHeaderDropDownFields;
  onClose?: () => void;
}

export const theming = Object.freeze({
  Transparent: 'bg-fill',
  Red: 'theme-red bg-fill',
  Blue: 'theme-blue bg-fill',
  Plum: 'theme-plum bg-fill',
});
