/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Link } from '../../Atoms/Link/Link';

// Models
import { LinkButtonProps } from './LinkButton.model';
import { variants, borderVariants } from '../Button/Button.model';

/**
 * Link component
 * @param props - Component props.
 */
export const LinkButton: FunctionComponent<LinkButtonProps> = ({
  children,
  className,
  to,
  openInNewTab,
  variant = 'primary',
  arrowDirection = 'Right',
  hasIcon = false,
}) => {
  if (variant === 'Transparent') {
    return (
      <Link
        className={`text-xs transition-colors lg:text-2xl`}
        to={to}
        openInNewTab={openInNewTab}
        variant="base"
        arrowDirection={arrowDirection}
      >
        {children}
      </Link>
    );
  }

  return (
    <Link
      to={to}
      openInNewTab={openInNewTab}
      variant="none"
      arrowDirection={arrowDirection}
      className={`${
        className || ''
      } group relative inline-flex min-w-max overflow-hidden 
      ${arrowDirection === 'Right' ? 'pr-[18px]' : ''} 
      ${arrowDirection === 'Left' ? 'pl-[18px]' : ''}`}
    >
      {!hasIcon && (
        <span
          className={`w-full ${variants[variant]} border-y 
          ${arrowDirection === 'None' ? 'rounded border px-6 py-2.5' : 'py-2'} 
          ${arrowDirection === 'Right' ? 'rounded-l border-l pl-6 pr-4' : ''} 
          ${arrowDirection === 'Left' ? 'rounded-r border-r pr-6 pl-4' : ''} 
          ${
            borderVariants[variant]
          } relative inline-flex text-xs transition-colors lg:text-2xl lg:leading-4`}
        >
          {children}
        </span>
      )}
      {hasIcon && (
        <span
          className={`w-full ${variants[variant]} border-y 
          ${arrowDirection === 'None' ? 'rounded border p-2.5' : 'py-2'} 
          ${
            arrowDirection === 'Right' ? 'rounded-l border-l pl-2.5 pr-1.5' : ''
          } 
          ${
            arrowDirection === 'Left' ? 'rounded-r border-r pr-2.5 pl-1.5' : ''
          } 
          ${
            borderVariants[variant]
          } relative inline-flex items-center justify-between text-xs transition-colors lg:text-15px lg:leading-4`}
        >
          {children}
        </span>
      )}

      {arrowDirection !== 'None' && (
        <span
          className={`clip-button_${arrowDirection?.toLowerCase()} ${
            variants[variant]
          } rounded border ${borderVariants[variant]} transition-colors`}
        ></span>
      )}
    </Link>
  );
};
