/* Dependencies */
import { FunctionComponent } from 'react';

// Models
import { MegaDropdownMenuProps, theming } from './MegaDropdownMenu.model';
import {
  TypeHeaderDrawerCardGridFields,
  TypeMoleculeDrawerMenuItemsFields,
} from '../../../models/contentful';

// Helpers
import { getHeaderLinkUrl } from '../../../helpers/getHeaderLinkUrl/getHeaderLinkUrl';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';

// Components
import { Heading } from '../Heading/Heading';
import { PlainRichText } from '../PlainRichText/PlainRichText';
import { Paragraph } from '../Paragraph/Paragraph';
import { CTATandem } from '../../Molecules/CTATandem/CTATandem';
import { LinkButton } from '../LinkButton/LinkButton';
import { Link } from '../Link/Link';

/**
 *  Navigation MegaDropdownMenu
 * @param props - Component props
 */
export const MegaDropdownMenu: FunctionComponent<MegaDropdownMenuProps> = ({
  menuItem: { subheading, title, callToActions, drawerContent, theme },
  onClose,
}) => {
  const drawer = drawerContent.fields as TypeMoleculeDrawerMenuItemsFields;
  const drawerCards = drawerContent.fields as TypeHeaderDrawerCardGridFields;
  return (
    <div className={`flex shadow-xl`}>
      <div
        className={`${theming[theme?.fields.colourScheme]} grid ${
          !drawerCards.cards ? 'w-[70%] grid-cols-2' : 'grid-cols-3'
        } divide-x ${
          theme?.fields.colourScheme === 'Plum'
            ? 'divide-red'
            : ' divide-button-secondary'
        } px-4 lg:py-12 xl:py-16`}
      >
        {/* First Box */}
        <div className="flex min-h-400 flex-col justify-between lg:px-10 xl:px-14">
          <div>
            {subheading && (
              <Heading
                level="h2"
                style="Heading 6"
                className={`mb-4 uppercase xl:mb-5 ${
                  theme?.fields.colourScheme === 'Plum'
                    ? 'text-red'
                    : 'text-secondary'
                }`}
              >
                <PlainRichText content={subheading} />
              </Heading>
            )}
            {title && (
              <Heading
                level="h3"
                className="mb-4 break-words text-primary xl:mb-5 xl:text-5xl xl:leading-[1.15]"
              >
                {!callToActions?.[0].fields.internalLink &&
                  !callToActions?.[0].fields.externalLink && (
                    <PlainRichText content={title} />
                  )}
                {(callToActions?.[0].fields.internalLink ||
                  callToActions?.[0].fields.externalLink) && (
                  <div onClick={onClose}>
                    <Link
                      openInNewTab={callToActions?.[0].fields.openInNewTab}
                      to={getLinkUrl(callToActions?.[0])}
                      title={callToActions?.[0].fields.cmsTitle}
                      variant={'none'}
                    >
                      <PlainRichText content={title} />
                    </Link>
                  </div>
                )}
              </Heading>
            )}
          </div>
          <div onClick={onClose}>
            <CTATandem
              animation={false}
              callsToAction={callToActions}
              className="flex flex-wrap items-center justify-start"
            />
          </div>
        </div>
        {/* Second Box */}
        {drawer.menuItems && (
          <div className="flex min-h-400 flex-col justify-between lg:px-10 xl:px-14">
            {drawer.menuItems?.map((item, index) => (
              <div className="group" key={index}>
                {item.fields.subheading && (
                  <Heading
                    level="h2"
                    style="Heading 6"
                    className={`mb-4 uppercase xl:mb-5 ${
                      theme?.fields.colourScheme === 'Plum'
                        ? 'text-red'
                        : 'text-secondary'
                    }`}
                  >
                    <PlainRichText content={item.fields.subheading} />
                  </Heading>
                )}
                {item.fields.title && (
                  <Heading
                    level="h3"
                    className="mb-4 text-primary lg:text-24px xl:mb-5 xl:text-4xl xl:leading-tight"
                  >
                    {!item.fields.internalLink && !item.fields.externalLink && (
                      <PlainRichText content={item.fields.title} />
                    )}
                    {(item.fields.internalLink || item.fields.externalLink) && (
                      <div onClick={onClose}>
                        <Link
                          openInNewTab={item.fields.openInNewTab}
                          to={getHeaderLinkUrl(item.fields)}
                          title={item.fields.cmsTitle}
                          variant={'none'}
                        >
                          <PlainRichText content={item.fields.title} />
                        </Link>
                      </div>
                    )}
                  </Heading>
                )}
                {item.fields.bodyCopy && (
                  <Paragraph className="text-primary group-first:mb-6 lg:text-2xl lg:leading-6 xl:text-base">
                    <PlainRichText content={item.fields.bodyCopy} />
                  </Paragraph>
                )}
              </div>
            ))}
          </div>
        )}
        {/* Second Box with Cards */}
        {drawerCards.cards && (
          <div className="col-span-2 min-h-400 lg:px-10 xl:px-14">
            {subheading && (
              <Heading
                level="h2"
                style="Heading 6"
                className={`mb-6 uppercase xl:mb-8 ${
                  theme?.fields.colourScheme === 'Plum'
                    ? 'text-red'
                    : 'text-secondary'
                }`}
              >
                <PlainRichText content={drawerCards.subheading} />
              </Heading>
            )}
            <div className="grid grid-cols-2 gap-5 xl:grid-cols-3">
              {drawerCards.cards?.map((item, index) => (
                <div
                  key={index}
                  className="relative flex min-h-[125px] flex-col items-center justify-center rounded-md bg-white p-6 text-center xl:min-h-150"
                >
                  {item.fields.title && (
                    <Heading
                      level="h3"
                      style="Heading 6"
                      className={`mb-4 uppercase text-plum lg:font-medium`}
                    >
                      <PlainRichText content={item.fields.title} />
                    </Heading>
                  )}
                  {item.fields.bodyCopy && (
                    <Paragraph className="text-15px text-plum">
                      <PlainRichText content={item.fields.bodyCopy} />
                    </Paragraph>
                  )}
                  {(item.fields.internalLink || item.fields.externalLink) && (
                    <div onClick={onClose}>
                      <Link
                        openInNewTab={item.fields.openInNewTab}
                        to={getHeaderLinkUrl(item.fields)}
                        title={item.fields.cmsTitle}
                        variant={'none'}
                        className="absolute top-0 left-0 h-full w-full"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Third Box out of the grid */}
      {drawer.menuItemFeatured && (
        <div
          className={`flex shrink grow basis-0 flex-col items-center justify-center p-8 ${
            theming[drawer.menuItemFeatured.fields.theme?.fields.colourScheme]
          }`}
        >
          <div className="max-w-[300px]">
            {drawer.menuItemFeatured.fields.title && (
              <Heading
                level="h2"
                style="Heading 3"
                className={`mb-8 text-primary lg:leading-9`}
              >
                <PlainRichText content={drawer.menuItemFeatured.fields.title} />
              </Heading>
            )}
            {drawer.menuItemFeatured.fields.bodyCopy && (
              <Paragraph className="mb-8 text-24px text-primary">
                <PlainRichText
                  content={drawer.menuItemFeatured.fields.bodyCopy}
                />
              </Paragraph>
            )}
            {drawer.menuItemFeatured.fields.callToAction && (
              <div onClick={onClose}>
                <LinkButton
                  variant={
                    drawer.menuItemFeatured.fields.callToAction.fields.theme
                      ?.fields.colourScheme
                  }
                  openInNewTab={
                    drawer.menuItemFeatured.fields.callToAction.fields
                      .openInNewTab
                  }
                  to={getLinkUrl(drawer.menuItemFeatured.fields.callToAction)}
                >
                  {drawer.menuItemFeatured.fields.callToAction.fields.title}
                </LinkButton>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
