/* Dependencies */
import { TypeTemplateHeaderFields } from '../../../models/contentful';

export interface NavigationDesktopProps {
  menuItems: TypeTemplateHeaderFields['menuItems'];
}

export const borderTheme = Object.freeze({
  Transparent: 'border-b-white',
  Red: 'border-b-red',
  Blue: 'border-b-blue',
  Plum: 'border-b-plum',
});
