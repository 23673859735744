/* Dependencies */
import { Asset } from 'contentful';

// Models
import { prefixUrl } from '../prefixUrl/prefixUrl';

/**
 * Generate SrcSet for an asset
 * @param image - The asset to generate the SrcSet for.
 * @returns
 */
export const generateSrcSet = (image: Asset): string => {
  const srcSetSizes = ['640w', '750w', '828w', '1080w', '1200w'];
  return srcSetSizes
    .map(
      (size) =>
        `${prefixUrl(image.fields.file.url)}&w=${size.replace(
          'w',
          ''
        )}&q=75 ${size}`
    )
    .join(', ');
};
