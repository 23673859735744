/**
 * Prefix Slug with /
 * @param url - The url to be prefixed.
 * @returns
 */
export const prefixSlug = (url: string): string => {
  if (url.charAt(0) === '/') {
    return url;
  }
  return `/${url}`;
};
