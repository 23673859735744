/* Dependencies */
import { Entry } from 'contentful';

// Clients
import { contentfulClient } from './clients/contentfulClient';
import { contentfulPreviewClient } from './clients/contentfulPreviewClient';

// Models
import { ArticlePage } from '../../models/Contentful.model';
import {
  RetrievePageParams,
  RetrieveRecommendedArticlesParams,
  RetrieveLatestInsightsAndEventsParams,
  RetrieveLatestInsightResponse,
  RetrieveLatestEventsResponse,
  RetrieveLatestCaseStudiesResponse,
  RetrieveLatestCaseStudiesParams,
} from './Contentful.model';
import {
  TypeTemplateInsightPage,
  TypeTemplateFooter,
  TypeTemplateHeader,
  TypeTemplateEventPage,
  TypeTemplatePageFields,
  TypeSlug,
  TypeForm,
  TypeFormFields,
  TypeTemplateCaseStudyPage,
} from '../../models/contentful';

/**
 * Contentful Services
 * @class
 */
export class ContentfulServices {
  /**
   * Retrieve a desired page.
   * @param params - Request parameters.
   * @param preview - Whether or not to use the preview client.
   */
  async retrievePage<PageType>(params: RetrievePageParams, preview: boolean) {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the page.
    const response = await client.getEntries<PageType>({
      limit: 1,
      locale: params.locale,
      content_type: params.pageContentType,
      'fields.slug.sys.contentType.sys.id': 'slug',
      'fields.slug.fields.slug': params.slug,
      include: 4,
    });

    // Clean response
    const {
      items: [page],
    } = JSON.parse(response.stringifySafe());

    // Return the page or null.
    return page || null;
  }

  /**
   * Retrieve a desired page.
   * @param params - Request parameters.
   * @param preview - Whether or not to use the preview client.
   */
  async retrievePageSlugById(id: string, preview: boolean): Promise<TypeSlug> {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the page.
    const {
      fields: { slug },
    } = await client.getEntry<TypeTemplatePageFields>(id);

    // Return the page or null.
    return slug || null;
  }

  /**
   * Retrieve all available pages.
   * @param params - Request parameters.
   * @param preview - Whether or not to use the preview client.
   */
  async retrieveAllPages<PageType>(
    params: RetrievePageParams,
    preview: boolean
  ) {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the pages.
    const response = await client.getEntries<PageType>({
      limit: 1000,
      locale: params.locale,
      content_type: params.pageContentType,
      include: 1,
      select: 'fields.slug,fields.title',
    });

    // Clean response
    const { items } = JSON.parse(response.stringifySafe());

    // Return the page or null.
    return items;
  }

  /**
   * Retrieve recommended articles.
   * @param params - Request parameters.
   * @param preview - Whether or not to use the preview client.
   */
  async retrieveRecommendedArticles(
    params: RetrieveRecommendedArticlesParams,
    preview: boolean
  ) {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Store the items
    const articles: Entry<ArticlePage>[] = [];

    // Retrieve the page.
    const nextArticles = await client.getEntries<ArticlePage>({
      limit: 4,
      'sys.createdAt[lt]': params.date,
      order: '-sys.createdAt',
      locale: params.locale,
      content_type: 'templateInsightPage',
      include: 4,
    });

    // Store the next items.
    articles.push(...JSON.parse(nextArticles.stringifySafe()).items);

    // Handle Uneven Count - Load Previous Articles
    if (nextArticles.items.length < 4) {
      const prevArticles = await client.getEntries<ArticlePage>({
        limit: 4 - nextArticles.items.length,
        'sys.createdAt[gt]': params.date,
        order: '-sys.createdAt',
        locale: params.locale,
        content_type: 'templateInsightPage',
      });

      // Store the previous items.
      articles.unshift(...JSON.parse(prevArticles.stringifySafe()).items);
    }

    // Return the page or null.
    return articles || null;
  }

  /**
   * Retrieve latest Insights pages.
   * @param params - Request parameters.
   */
  async retrieveLatestInsights(
    params: RetrieveLatestInsightsAndEventsParams,
    preview: boolean
  ): Promise<RetrieveLatestInsightResponse> {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the Insights pages.
    const insightsPages = await client.getEntries<TypeTemplateInsightPage>({
      limit: params.limit,
      order: '-fields.publishedDate',
      locale: params.locale,
      content_type: 'templateInsightPage',
      include: 2,
      select:
        'fields.slug,fields.author,fields.image,fields.title,fields.publishedDate,fields.tags',
      'fields.hideFromInsightsPage[ne]': true
    });

    // Clean response
    const { items, total } = JSON.parse(insightsPages.stringifySafe());

    // Remove Items with no fields
    const filteredItems: TypeTemplateInsightPage[] = items.filter(
      (item: TypeTemplateInsightPage) => item.fields
    );

    // Return the pages or null.
    return { items: filteredItems, total };
  }

  /**
   * Retrieve latest Events pages.
   * @param params - Request parameters.
   */
  async retrieveLatestEvents(
    params: RetrieveLatestInsightsAndEventsParams,
    preview: boolean
  ): Promise<RetrieveLatestEventsResponse> {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the Events pages.
    const eventsPages = await client.getEntries<TypeTemplateEventPage>({
      limit: params.limit,
      order: '-fields.eventDate',
      locale: params.locale,
      content_type: 'templateEventPage',
      include: 2,
      select:
        'fields.slug,fields.image,fields.tags,fields.title,fields.eventDate,fields.eventEndDate,fields.location',
      'fields.hideFromEventsPage[ne]': true
    });

    // Clean response
    const { items, total } = JSON.parse(eventsPages.stringifySafe());

    // Remove Items with no fields
    const filteredItems: TypeTemplateEventPage[] = items.filter(
      (item: TypeTemplateEventPage) => item.fields
    );

    // Return the pages or null.
    return { items: filteredItems, total };
  }

    /**
   * Retrieve latest Case Studies pages.
   * @param params - Request parameters.
   */
    async retrieveLatestCaseStudies(
      params: RetrieveLatestCaseStudiesParams,
      preview: boolean
    ): Promise<RetrieveLatestCaseStudiesResponse> {
      // Set the client
      const client = preview ? contentfulPreviewClient : contentfulClient;
  
      // Retrieve the Case Studies pages.
      const caseStudiesPages = await client.getEntries<TypeTemplateCaseStudyPage>({
        limit: params.limit,
        order: '-fields.publishedDate',
        locale: params.locale,
        content_type: 'templateCaseStudyPage',
        include: 2,
        select:
        'fields.slug,fields.image,fields.title,fields.publishedDate,fields.tags',
      'fields.hideFromCaseStudyPage[ne]': true
      });
  
      // Clean response
      const { items, total } = JSON.parse(caseStudiesPages.stringifySafe());
  
      // Remove Items with no fields
      const filteredItems: TypeTemplateCaseStudyPage[] = items.filter(
        (item: TypeTemplateCaseStudyPage) => item.fields
      );
  
      // Return the pages or null.
      return { items: filteredItems, total };
    }

  /**
   * Retrieve's the footer.
   * @param preview - Whether or not to use the preview client.
   */

  async retrieveFooter(preview: boolean): Promise<TypeTemplateFooter> {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the page.
    const response = await client.getEntries({
      limit: 1,
      locale: 'en-GB',
      content_type: 'templateFooter',
      include: 4,
    });

    // Clean response
    const {
      items: [footer],
    } = JSON.parse(response.stringifySafe());

    // Return the footer or null.
    return footer as TypeTemplateFooter;
  }

  /**
   * Retrieve's the header.
   * @param preview - Whether or not to use the preview client.
   */
  async retrieveHeader(preview: boolean): Promise<TypeTemplateHeader> {
    // Set the client
    const client = preview ? contentfulPreviewClient : contentfulClient;

    // Retrieve the page.
    const response = await client.getEntries({
      limit: 1,
      locale: 'en-GB',
      content_type: 'templateHeader',
      include: 5,
    });

    // Clean response
    const {
      items: [header],
    } = JSON.parse(response.stringifySafe());

    // Return the footer or null.
    return header as TypeTemplateHeader;
  }

  /**
   * Returns the entry for a given form id
   * @param id
   * @returns
   */
  async getFormConfig(id: string): Promise<TypeForm> {
    return contentfulClient.getEntry<TypeFormFields>(id);
  }
}
