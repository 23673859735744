/* Dependencies */
import {
  Fragment,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Popover, Transition } from '@headlessui/react';

// Helpers
import { getHeaderLinkUrl } from '../../../helpers/getHeaderLinkUrl/getHeaderLinkUrl';

// Components
import { Link } from '../../Atoms/Link/Link';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { MegaDropdownMenu } from '../../Atoms/MegaDropdownMenu/MegaDropdownMenu';

// Models
import { NavigationDesktopProps, borderTheme } from './NavigationDesktop.model';
import {
  TypeMoleculeHeaderDropDownFields,
  TypeMoleculeHeaderMenuItemFields,
} from '../../../models/contentful';
import { getWindowWidth } from '../../../helpers/getWindowWidth/getWindowWidth';

/**
 * Desktop Navigation
 * @param fields - Component props
 */
export const NavigationDesktop: FunctionComponent<NavigationDesktopProps> = ({
  menuItems,
}) => {
  const [panelActive, setPanelActive] = useState(false);
  const wrapperRef = useRef<any>();

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', panelActive);
    document.body.classList.toggle('fixed', panelActive);
    document.body.classList.toggle('w-full', panelActive);

    const resizeListener = () => {
      if (getWindowWidth() < 1024 && panelActive) {
        setPanelActive(false);
        document.body.classList.remove('overflow-hidden', 'fixed', 'w-full');
      }
    };
    window.addEventListener('resize', resizeListener);

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('resize', resizeListener);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [panelActive]);

  function handleClickOutside() {
    if (wrapperRef.current && panelActive) {
      setPanelActive(false);
    }
  }

  return (
    <Popover className="hidden shrink-0 lg:flex">
      <Popover.Group as="nav" className="flex space-x-10">
        {menuItems.map((menuEntry, menuEntryIndex) => {
          // Check if the entry contains a sub menu.
          let isSubmenu = Object.hasOwnProperty.call(
            menuEntry.fields,
            'drawerContent'
          );
          // Handle Regular Link
          if (!isSubmenu) {
            const menuItem =
              menuEntry.fields as TypeMoleculeHeaderMenuItemFields;
            return (
              <Link
                key={menuEntryIndex}
                openInNewTab={menuItem.openInNewTab}
                to={getHeaderLinkUrl(menuItem)}
                variant="none"
                className="flex items-center font-display-a text-15px font-extrabold uppercase tracking-wider focus:outline-none"
              >
                <PlainRichText content={menuItem.menuTitle} />
              </Link>
            );
          }

          // Handle Sub Menu\
          const menuItem = menuEntry.fields as TypeMoleculeHeaderDropDownFields;
          return (
            <Popover key={menuEntryIndex}>
              {({ open, close }) => (
                <>
                  <Popover.Button
                    ref={wrapperRef}
                    onClick={() => {
                      setPanelActive(!open);
                    }}
                    className={`group relative flex items-center focus:outline-none`}
                  >
                    <span className="font-display-a text-15px font-extrabold uppercase tracking-wider text-primary hover:text-secondary focus:outline-none active:text-secondary">
                      {menuItem.menuTitle}
                    </span>
                    <img
                      src="/assets/svg/chevronNavigation.svg"
                      alt="v"
                      width="7"
                      height="10"
                      className={`${
                        open ? 'rotate-180' : ''
                      } ml-2 w-2 transition-transform`}
                    />
                    <div className="absolute -bottom-24 left-1/2 z-20 -translate-x-1/2 xl:-bottom-32">
                      <Transition
                        enter="transition ease-in duration-[220ms]"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-out duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <div
                          className={`border-b- border-x-[100px] border-b-[75px] border-t-0 border-solid border-x-transparent xl:border-x-[140px] xl:border-b-[116px] ${
                            borderTheme[menuItem.theme?.fields.colourScheme]
                          }`}
                        ></div>
                      </Transition>
                    </div>
                  </Popover.Button>
                  <Popover.Overlay className="absolute inset-0 top-[120px] h-screen bg-black opacity-50" />
                  <Transition
                    as={Fragment}
                    enter="transition ease-in duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-out duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel
                      className="absolute left-1/2 z-20 mt-24 max-h-[calc(100vh-190px)] w-full max-w-1716 -translate-x-1/2 transform overflow-auto px-4 xl:mt-32 xl:max-h-[calc(100vh-210px)]"
                      onClick={() => {
                        close();
                        setPanelActive(false);
                      }}
                    >
                      <MegaDropdownMenu
                        menuItem={menuItem}
                        onClose={() => {
                          close();
                          setPanelActive(false);
                        }}
                      />
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          );
        })}
      </Popover.Group>
    </Popover>
  );
};
