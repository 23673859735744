import { TypeHeadingFields } from '../../../models/contentful/TypeHeading';

export type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface HeadingProps extends React.PropsWithChildren {
  className?: string;
  font?: TypeHeadingFields['fontFamily'];
  level: Level;
  style?: keyof typeof Styles;
}

export const Fonts = Object.freeze({
  Souvenir: 'font-display-s',
  Avenir: 'font-display-a font-black',
});

export const Styles = Object.freeze({
  'Heading 1': 'h1',
  'Heading 2': 'h2',
  'Heading 3': 'h3',
  'Heading 4': 'h4',
  'Heading 5': 'h5',
  'Heading 6': 'h6',
});

/**
 * Get the style associated with the heading.
 * @param level - The level of the heading.
 * @returns
 */
export const getHeadingStyle = (
  level: Level,
  font: TypeHeadingFields['fontFamily']
): string => {
  switch (level) {
    case 'h1': {
      return `${
        font === 'Avenir' ? 'text-3xl lg:text-6xl' : 'text-5xl lg:text-7xl'
      } tracking-tight leading-none`;
    }
    case 'h2': {
      return 'text-3xl tracking-tight leading-none lg:text-5xl lg:leading-tight';
    }
    case 'h3': {
      return 'text-base leading-snug lg:text-4xl lg:leading-tight';
    }
    case 'h4': {
      return 'text-base lg:text-3xl font-display-s';
    }
    case 'h5': {
      return 'text-2xl lg:text-base font-display-s';
    }
    case 'h6': {
      return 'text-xs leading-snug font-display-a font-extrabold lg:text-2xl lg:leading-tight';
    }
    default: {
      return '';
    }
  }
};
