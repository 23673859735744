/* Dependencies */
import { Asset } from 'contentful';

export type ImageAspectRatio =
  | '1x1'
  | '4x3'
  | '16x9'
  | '12x16'
  | 'fluid'
  | 'absolute'
  | 'auto'
  | 'original';

export interface ImageAspectRatioClasses {
  /**
   * Class name for 1x1 aspect ratio.
   */
  '1x1': string;
  /**
   * Class name for 4x3 aspect ratio.
   */
  '4x3': string;
  /**
   * Class name for 16x9 aspect ratio.
   */
  '16x9': string;
  /**
   * Class name for 12x16 aspect ratio.
   */
  '12x16': string;
  /**
   * Class name for fluid aspect ratio.
   */
  fluid: string;
}

export const imageAspectRatioClasses: ImageAspectRatioClasses = Object.freeze({
  '1x1': 'aspect-w-1 aspect-h-1',
  '4x3': 'aspect-w-4 aspect-h-3',
  '16x9': 'aspect-w-16 aspect-h-9',
  '12x16': 'aspect-w-12 aspect-h-16',
  fluid: '',
});

export interface ImageProps {
  /**
   * Image File from contentful.
   */
  file: Asset;
  /**
   * Aspect ratio to be applied.
   */
  aspectRatio?: ImageAspectRatio;
  /**
   * Priority
   */
  priority?: boolean;
  /**
   * Height
   */
  height?: number;
  /**
   * ClassName
   */
  className?: string;
  /**
   * Object-fit
   */
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
}
