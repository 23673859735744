export interface Variants {
  primary: string;
  secondary: string;
  tertiary: string;
  Red: string;
  Blue: string;
  Plum: string;
  Invert: string;
  Hollow: string;
  Transparent: string;
}

export const borderVariants: Variants = Object.freeze({
  primary: 'border-br-button-primary',
  secondary: 'border-br-button-secondary',
  tertiary: '',
  Red: 'border-transparent',
  Blue: 'border-transparent',
  Plum: 'border-plum',
  Invert: 'border-transparent',
  Hollow: 'border-transparent',
  Transparent: '',
});

export const variants: Variants = Object.freeze({
  primary:
    'text-button-primary bg-button-primary hover-hover:group-hover:text-button-primary-hover hover-hover:group-hover:bg-button-primary-hover',
  secondary:
    'text-button-secondary bg-button-secondary hover-hover:group-hover:text-button-secondary-hover hover-hover:group-hover:bg-button-secondary-hover',
  tertiary:
    'text-button-tertiary bg-button-tertiary hover-hover:group-hover:text-button-tertiary-hover hover-hover:group-hover:bg-button-tertiary-hover',
  Red: 'text-white bg-red hover-hover:group-hover:text-button-secondary-hover hover-hover:group-hover:bg-button-secondary-hover',
  Blue: 'text-plum bg-blue hover-hover:group-hover:text-button-primary-hover hover-hover:group-hover:bg-button-primary-hover',
  Plum: 'text-white bg-plum hover-hover:group-hover:bg-white hover-hover:group-hover:text-plum',
  Invert:
    'text-plum bg-blue hover-hover:group-hover:text-white hover-hover:group-hover:bg-purple',
  Hollow:
    'text-plum bg-white hover-hover:group-hover:text-white hover-hover:group-hover:bg-plum',
  Transparent: '',
});
