/* Dependencies */
import { TypeCallToAction } from '../../models/contentful';
import { getSlugUrl } from '../getSlugUrl/getSlugUrl';

/**
 * Get the link URL
 * @param link - Call to Action
 * @returns
 */
export const getLinkUrl = (link?: TypeCallToAction): string => {
  // Handle invalid / missing link
  if (!link) {
    return '';
  }

  // Handle internal link
  if (link.fields.internalLink && link.fields.internalLink.fields) {
    return getSlugUrl(link.fields.internalLink);
  }

  // Handle Asset
  if (link.fields.assetLink && link.fields.assetLink.fields) {
    return link.fields.assetLink.fields.file.url;
  }

  // Handle External Link
  else if (link.fields.externalLink) {
    return link.fields.externalLink;
  }

  // Handle No Link
  return '';
};
