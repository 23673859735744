/* Dependencies */
import { FunctionComponent } from 'react';

// Models
import { ContainerProps } from './Container.model';

/**
 * Container - Creates a max-width section wrapper.
 * @param props - Required component props.
 * @returns
 */
export const Container: FunctionComponent<ContainerProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`mx-auto max-w-1716 px-10 ${className || ''}`}>
      {children}
    </div>
  );
};
