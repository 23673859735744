/* Dependencies */
import { FunctionComponent } from 'react';

// Components
import { Footer } from '../../components/Organisms/Footer/Footer';
import { Header } from '../../components/Organisms/Header/Header';

// Models
import { MainLayoutProps } from './MainLayout.model';

/**
 * Main Layout
 * @param props - Required component props.
 * @returns
 */
export const MainLayout: FunctionComponent<MainLayoutProps> = ({
  children,
  header,
  footer,
}) => {
  return (
    <>
      <Header {...header} />
      <main className="w-full pt-24 lg:pt-[120px]">{children}</main>
      <Footer {...footer} />
    </>
  );
};
