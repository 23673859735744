/**
 * Prefix Url
 * @param url - URl to be prefixed.
 * @returns
 */
export const prefixUrl = (url: string): string => {
  if (url.startsWith('http')) {
    return `${url}?fm=webp`;
  }

  return `https:${url}?fm=webp`;
};
