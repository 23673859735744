/* Dependencies */
import { TypeAtomFooterLinkFields } from '../../models/contentful';
import { getSlugUrl } from '../getSlugUrl/getSlugUrl';

/**
 * Get the footer link URL
 * @param link - Call to Action
 * @returns
 */
export const getFooterLinkUrl = (link?: TypeAtomFooterLinkFields): string => {
  // Handle invalid / missing link
  if (!link) {
    return '';
  }

  // Handle internal link
  if (link.internalLink && link.internalLink.fields) {
    return getSlugUrl(link.internalLink);
  }

  // Handle External Link
  else if (link.externalLink) {
    return link.externalLink;
  }

  // Handle No Link
  return '';
};
