/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { prefixUrl } from '../../../helpers/prefixUrl/prefixUrl';
import { generateSrcSet } from '../../../helpers/generateSrcSet/generateSrcSet';
import { LogoProps } from './Logo.model';

/**
 * Logo Component
 * @params props - Required component props.
 */
export const Logo: FunctionComponent<LogoProps> = ({
  file,
  className = 'h-full w-auto object-contain',
  priority = false,
}) => {
  // Handle Conentful Image Removal
  if (!file || !file.fields || !file.fields.file) {
    return null;
  }

  // Image Sizing
  const imageWidth = file.fields.file.details.image
    ? file.fields.file.details.image.width
    : 0;
  const imageHeight = file.fields.file.details.image
    ? file.fields.file.details.image.height
    : 0;

  return (
    <img
      alt={file.fields.description}
      width={imageWidth}
      height={imageHeight}
      srcSet={generateSrcSet(file)}
      src={prefixUrl(file.fields.file.url)}
      className={className}
      loading={priority ? 'eager' : 'lazy'}
    />
  );
};
