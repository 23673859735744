/* Dependencies */
import type { AppProps } from 'next/app';

// Services
import { httpClient } from '../src/services/httpClient';

// Layouts
import { MainLayout } from '../src/layouts/MainLayout/MainLayout';

// Styles
import '../styles/globals.css';

// Models
import {
  TypeTemplateFooter,
  TypeTemplateHeader,
} from '../src/models/contentful';
interface MyAppProps extends AppProps {
  header: TypeTemplateHeader;
  footer: TypeTemplateFooter;
}

/**
 * Base Application Component
 * @param props - Required component props
 */
function MyApp({ Component, pageProps, header, footer }: MyAppProps) {
  return (
    <MainLayout header={header} footer={footer}>
      <Component {...pageProps} />
    </MainLayout>
  );
}

MyApp.getInitialProps = async (context: { preview: boolean }) => {
  const header = await httpClient.contentful.retrieveHeader(context.preview);
  const footer = await httpClient.contentful.retrieveFooter(context.preview);

  // Return the data to the app
  return {
    header: header,
    footer: footer,
  } as Partial<MyAppProps>;
};

export default MyApp;
