/* Dependencies */
import { FunctionComponent } from 'react';

// Models
import { TypeTemplateFooter } from '../../../models/contentful';
import { ComponentWrapper } from '../../Molecules/ComponentWrapper/ComponentWrapper';
import { Container } from '../../Molecules/Container/Container';
import { LinkButton } from '../../Atoms/LinkButton/LinkButton';
import { PlainRichText } from '../../Atoms/PlainRichText/PlainRichText';
import { Image } from '../../Molecules/Image/Image';
import { Heading } from '../../Atoms/Heading/Heading';
import { Link } from '../../Atoms/Link/Link';
import { getLinkUrl } from '../../../helpers/getLinkUrl/getLinkUrl';
import { getFooterLinkUrl } from '../../../helpers/getFooterLinkUrl/getFooterLinkUrl';
import { Logo } from '../../Atoms/Logo/Logo';

/**
 * Footer
 * @params props - Required component props.
 */
export const Footer: FunctionComponent<TypeTemplateFooter> = ({
  fields: {
    logo,
    title,
    newsletter,
    sustainabilityText,
    navigationColumn1,
    navigationColumn2,
    socialMedia,
    logos,
    smallCopy,
    bottomNavigation,
  },
}) => {
  return (
    <footer>
      <ComponentWrapper backgroundColor="Red" className="text-white">
        <Container className="xs:px-5 py-6 md:grid md:grid-cols-2 lg:py-10">
          {/* Left side */}
          <div>
            {/* logo */}
            <div className="mb-10 inline-block w-32">
              <Image file={logo} />
            </div>
            {/* title */}
            <Heading
              className="mb-5 md:max-w-[70%]"
              level="h2"
              font={`${title?.fields.fontFamily}`}
            >
              <PlainRichText content={title.fields.content} />
            </Heading>
            {/* signup newsletter */}
            <div className="mb-10">
              <p className="mb-5 lg:max-w-[40%] lg:text-[25px]">
                <PlainRichText content={newsletter?.fields.title} />
              </p>
              <LinkButton
                variant="primary"
                openInNewTab={
                  newsletter?.fields.callToAction.fields.openInNewTab
                }
                to={getLinkUrl(newsletter?.fields.callToAction)}
              >
                {newsletter?.fields.callToAction.fields.title}
              </LinkButton>
            </div>
          </div>
          {/* ./ Left side */}

          {/* Right side */}
          <div>
            {/* nav col 1 */}
            <div className="mb-10 grid grid-cols-2 font-display-a md:ml-auto md:grid-cols-[66%_34%] lg:mb-24 lg:w-3/5">
              <div className="md:grid md:grid-cols-2">
                <ul className="ml-auto">
                  {navigationColumn1 &&
                    navigationColumn1.map((item, index) => {
                      return (
                        <li className="mt-0 mb-[14px]" key={index}>
                          <Link
                            variant="none"
                            openInNewTab={item.fields.openInNewTab}
                            to={getFooterLinkUrl(item.fields)}
                          >
                            {item.fields.title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <ul className="ml-auto">
                  {/* nav col 2 */}
                  {navigationColumn2 &&
                    navigationColumn2.map((item, index) => {
                      return (
                        <li className="mt-0 mb-[14px]" key={index}>
                          <Link
                            variant="none"
                            openInNewTab={item.fields.openInNewTab}
                            to={getFooterLinkUrl(item.fields)}
                          >
                            {item.fields.title}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
              {/* social media col 3 */}
              {socialMedia && (
                <div className="md:ml-auto">
                  <PlainRichText
                    content={socialMedia.fields.title.fields.content}
                  />
                  <ul className="flex flex-1 gap-[6px] md:gap-3">
                    <li>
                      <Link
                        openInNewTab={true}
                        variant="none"
                        className="group"
                        to={socialMedia?.fields.twitterUrl ?? ''}
                      >
                        <span className="sr-only">Twitter</span>
                        <svg
                          className="h-4 w-4 text-primary group-hover:text-secondary lg:h-7 lg:w-7"
                          width="28"
                          height="25"
                          viewBox="0 0 28 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M25.6806 3.26075L27.5198 3.47554L26.693 5.1324C26.2899 5.94038 25.8863 6.74837 25.4824 7.55635C25.4344 7.72748 25.3503 7.87751 25.2258 8.07283C25.1737 8.15445 24.996 8.41406 24.9877 8.42653C24.9407 8.49691 24.906 8.55241 24.8811 8.59688V11.702C24.8811 19.6476 17.1916 24.6967 9.9298 24.6967C8.44386 24.6967 7.31488 24.6255 6.02214 24.3434C3.89469 23.8791 2.31145 22.9563 1.58696 21.3434L0.841069 19.6829L2.65376 19.5162C4.2936 19.3654 5.71539 19.053 6.80726 18.6346C3.80969 18.1526 2.12988 16.836 2.12988 14.3636V13.0636H3.42988C3.7155 13.0636 3.97305 13.0404 4.2033 12.9978C1.9536 11.6527 0.826784 9.49493 0.826784 6.50198C0.826538 6.37441 0.826538 6.37441 0.825771 6.23802C0.817319 4.72943 0.898349 3.92336 1.31133 2.89457C1.576 2.23523 1.95858 1.62485 2.47346 1.06557L3.45446 0L4.40822 1.09001C7.55132 4.68209 10.6583 6.86134 13.8306 7.12494C13.8442 3.81036 16.3492 1.30198 19.6808 1.30198C21.234 1.30198 22.6429 1.89152 23.75 2.95454C24.356 3.07906 24.9996 3.18122 25.6806 3.26075ZM22.8064 5.41118L22.4079 5.32094L22.1326 5.01892C21.4672 4.28867 20.6319 3.90254 19.6808 3.90254C17.7789 3.90254 16.4305 5.25764 16.4305 7.15254C16.4305 7.46222 16.4225 7.6568 16.384 7.91255C16.23 8.93307 15.6576 9.75254 14.4805 9.75254C10.5831 9.75254 7.06694 7.6646 3.63824 4.09852C3.46148 4.63585 3.41991 5.18818 3.42571 6.22401C3.42649 6.36255 3.42649 6.36255 3.42676 6.50254C3.42676 9.16495 4.50539 10.6213 7.05928 11.433L7.9655 11.721V12.6719C7.9655 13.825 6.87306 14.9104 5.27466 15.3966C6.00738 15.9525 7.50588 16.2525 9.93024 16.2525H11.2302V17.5525C11.2302 19.4151 9.09008 20.8734 5.85715 21.635C6.91149 21.958 8.31533 22.0972 9.92978 22.0972C15.9285 22.0972 22.281 17.926 22.281 11.7025V8.45254C22.281 8.07062 22.3917 7.73911 22.5757 7.39443C22.6476 7.25966 22.7268 7.13118 22.8245 6.98465C22.8429 6.95706 22.9111 6.8571 22.9653 6.77719L23.0683 6.57113C23.2353 6.23724 23.4022 5.90335 23.569 5.56946C23.3097 5.52035 23.0555 5.4676 22.8064 5.41118Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link
                        openInNewTab={true}
                        variant="none"
                        className="group"
                        to={socialMedia?.fields.linkedinUrl ?? ''}
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="h-4 w-4 text-primary group-hover:text-secondary lg:h-7 lg:w-7"
                          width="27"
                          height="26"
                          viewBox="0 0 27 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.12539 0H23.9254C25.3613 0 26.5254 1.16406 26.5254 2.6V23.4C26.5254 24.8359 25.3613 26 23.9254 26H3.12539C1.68945 26 0.525391 24.8359 0.525391 23.4V2.6C0.525391 1.16406 1.68945 0 3.12539 0ZM3.1252 2.6V23.4H23.9252V2.6H3.1252ZM14.8254 9.1C14.1523 9.1 13.4412 9.30574 12.8132 9.68779L12.2254 9.1H10.9254V18.2H13.5254V13C13.5254 12.2515 14.2975 11.7 14.8254 11.7H16.1254C16.6533 11.7 17.4254 12.2515 17.4254 13V18.2H20.0254V13C20.0254 10.5913 17.9375 9.1 16.1254 9.1H14.8254ZM8.32539 7.8C9.04336 7.8 9.62539 7.21797 9.62539 6.5C9.62539 5.78203 9.04336 5.2 8.32539 5.2C7.60742 5.2 7.02539 5.78203 7.02539 6.5C7.02539 7.21797 7.60742 7.8 8.32539 7.8ZM7.02539 9.1V18.2H9.62539V9.1H7.02539Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link
                        openInNewTab={true}
                        variant="none"
                        className="group"
                        to={socialMedia?.fields.facebookUrl ?? ''}
                      >
                        <span className="sr-only">Facebook</span>
                        <svg
                          className="h-4 w-4 text-primary group-hover:text-secondary lg:h-7 lg:w-7"
                          width="27"
                          height="26"
                          viewBox="0 0 27 26"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M3.52578 0H24.3258C25.7617 0 26.9258 1.16406 26.9258 2.6V23.4C26.9258 24.8359 25.7617 26 24.3258 26H3.52578C2.08984 26 0.925781 24.8359 0.925781 23.4V2.6C0.925781 1.16406 2.08984 0 3.52578 0ZM3.52568 2.6V23.4H24.3257V2.6H3.52568ZM12.7984 13.0057H15.2255V20.8H17.8255V13.0057H20.4026V10.4057H17.8255V9.1C17.8255 8.38203 18.4075 7.8 19.1255 7.8H20.4255V5.2H19.1255C16.9716 5.2 15.2255 6.94609 15.2255 9.1V10.4057H12.7984V13.0057Z"
                            fill="currentColor"
                          />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {/* logos */}
            <ul className="mb-14 flex flex-wrap items-center space-y-2 md:mb-6 md:flex-nowrap md:space-y-0 md:space-x-3 lg:justify-end lg:space-x-8 2xl:space-x-12">
              {logos &&
                logos.map(({ fields }, index) => {
                  return (
                    <li
                      className="w-1/2 first:my-0 md:w-1/3 lg:w-auto"
                      key={index}
                    >
                      <Logo
                        file={fields.asset}
                        className="mx-auto object-contain object-center"
                      />
                    </li>
                  );
                })}
            </ul>
            {/* small copy */}
            {smallCopy && (
              <small className="font-display-a">
                <PlainRichText content={smallCopy.fields.content} />
              </small>
            )}
          </div>
          {/* ./Right side */}
        </Container>
      </ComponentWrapper>

      {/* Bottom nav */}
      <ComponentWrapper backgroundColor="Plum" className="text-white">
        <Container className="xs:px-5 py-5 font-display-a">
          <div className="md:flex md:flex-row-reverse md:items-center md:justify-between">
            {/* nav */}
            <nav aria-labelledby="nav3" className="">
              <ul className="mb-4 flex gap-10 lg:mb-0">
                {bottomNavigation &&
                  bottomNavigation.map((item, index) => {
                    return (
                      <li className="m-0" key={index}>
                        <Link
                          variant="none"
                          openInNewTab={item.fields.openInNewTab}
                          to={getFooterLinkUrl(item.fields)}
                        >
                          <small>{item.fields.title}</small>
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            </nav>
            {/* small print */}
            <p className="mb-7 md:mb-0">
              <small>
                <PlainRichText content={sustainabilityText?.fields.content} />
              </small>
            </p>
            {/* copyright */}
            <small>Copyright MMT {new Date().getFullYear()}</small>
          </div>
        </Container>
      </ComponentWrapper>
      {/* ./ Bottom nav */}
    </footer>
  );
};
