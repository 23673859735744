/* Dependencies */
import { FunctionComponent } from 'react';

// Helpers
import { prefixUrl } from '@helpers/prefixUrl/prefixUrl';
import { generateSrcSet } from '@helpers/generateSrcSet/generateSrcSet';

// Models
import { imageAspectRatioClasses, ImageProps } from './Image.model';

/**
 * Image Component
 * @params props - Required component props.
 */
export const Image: FunctionComponent<ImageProps> = ({
  file,
  aspectRatio = 'fluid',
  priority = false,
  height,
  className = '',
  objectFit = 'cover',
}) => {
  // Handle Conentful Image Removal
  if (!file || !file.fields || !file.fields.file) {
    return null;
  }

  // Image Sizing
  const imageWidth = file.fields.file.details.image
    ? file.fields.file.details.image.width
    : 0;
  const imageHeight = file.fields.file.details.image
    ? file.fields.file.details.image.height
    : 0;

  // Handle Normal Fluid Images
  if (aspectRatio === 'fluid') {
    return (
      <img
        alt={file.fields.description}
        width={imageWidth}
        height={imageHeight}
        srcSet={generateSrcSet(file)}
        src={prefixUrl(file.fields.file.url)}
        className={`h-auto w-full ${className}`}
        style={{ height }}
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  // auto
  if (aspectRatio === 'auto') {
    return (
      <img
        alt={file.fields.description}
        width={imageWidth}
        height={imageHeight}
        srcSet={generateSrcSet(file)}
        src={prefixUrl(file.fields.file.url)}
        className={`mx-auto h-auto w-auto max-w-full ${className}`}
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  if (aspectRatio === 'original') {
    return (
      <img
        alt={file.fields.description}
        width={imageWidth}
        height={imageHeight}
        srcSet={generateSrcSet(file)}
        src={prefixUrl(file.fields.file.url)}
        className={`mx-auto h-auto w-auto ${className}`}
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  if (aspectRatio === 'absolute') {
    return (
      <img
        alt={file.fields.description}
        sizes="100vw"
        srcSet={generateSrcSet(file)}
        src={prefixUrl(file.fields.file.url)}
        className={`absolute top-0 left-0 h-full w-full object-${objectFit} object-center ${className}`}
        loading={priority ? 'eager' : 'lazy'}
      />
    );
  }

  return (
    <div className="relative w-full">
      <div className={`w-full ${imageAspectRatioClasses[aspectRatio]}`}></div>
      <img
        alt={file.fields.description}
        sizes="100vw"
        srcSet={generateSrcSet(file)}
        src={prefixUrl(file.fields.file.url)}
        className={`absolute top-0 left-0 h-full w-full object-${objectFit} object-center ${className}`}
        loading={priority ? 'eager' : 'lazy'}
      />
    </div>
  );
};
