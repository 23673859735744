/* Dependencies */
import { FunctionComponent, useEffect } from 'react';

// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';

// Models
import { ComponentWrapperProps, theme } from './ComponentWrapper.model';

/**
 * ComponentWrapper - Creates a section wrapper.
 * @param props - Required component props.
 * @returns
 */
export const ComponentWrapper: FunctionComponent<ComponentWrapperProps> = ({
  children,
  className,
  backgroundColor = 'Transparent',
}) => {
  useEffect(() => {
    AOS.init({
      throttleDelay: 5,
      offset: 50,
      duration: 750,
      delay: 150,
      once: true,
    });
  }, []);
  return (
    <section className={`${className || ''} ${theme[backgroundColor]}`}>
      {children}
    </section>
  );
};
