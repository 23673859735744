/* Dependencies */
import { TypeSlug } from '../../models/contentful';

/**
 * Get the ur from a slug
 * @param slug - CMS Slug selected.
 * @returns
 */
export const getSlugUrl = (slug?: TypeSlug): string => {
  // Handle invalid / missing link
  if (!slug) {
    return '';
  }

  // Handle internal link
  switch (slug.fields.slugType) {
    
    case 'Page (Default)': {
      return `/${slug.fields.slug}`;
    }
    case 'Event': {
      return `/events/${slug.fields.slug}`;
    }
    case 'Case Study': {
      return `/case-studies/${slug.fields.slug}`;
    }
    case 'Insight': {
      return `/insights/${slug.fields.slug}`;
    }
    case 'Job Description': {
      return `/job-descriptions/${slug.fields.slug}`;
    }
    default: {
      return `/${slug.fields.slug}`;
    }
  }
};
